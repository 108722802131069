import React from "react";
import { Helmet } from 'react-helmet';
import { navigate } from 'gatsby';
import cx from 'classnames';
import Layout from "../../layout/default";
import SEO from "../../components/seo";
import Title from '../../components/Title';
import PromotionContainer from './PromotionContainer';
import RoundButton from '../../components/RoundButton';
import * as s from './s.module.css';
import { drawingWinnersPromotionData, availableLeaderboards } from '../../data/promotions';
import config from '../../config';

const generateMeta = (pageContext, href) => {
    const meta = [];

    if (pageContext.description) {
        meta.push(
            <meta key="description" name="description" content={pageContext.description} />,
            <meta key="og:description" property="og:description" content={pageContext.description} />,
            <meta key="twitter:description" name="twitter:description" content={pageContext.description} />,
        );
    }

    if (pageContext.metaImage) {
        meta.push(
            <meta key="image" name="image" content={pageContext.metaImage} />,
            <meta key="og:image" property="og:image" content={pageContext.metaImage} />,
            <meta key="twitter:image" name="twitter:image" content={pageContext.metaImage} />,
        );
    }

    if (pageContext.mainHeader) {
        meta.push(
            <meta key="og:title" property="og:title" content={pageContext.mainHeader} />,
            <meta key="twitter:title" name="twitter:title" content={pageContext.mainHeader} />,
        );
    }

    if (href) {
        meta.push(
            <meta key="og:url" property="og:url" content={href} />,
        );
    }

    return meta;
};

class PromotionsTemplate extends React.Component<Props> {
    constructor(props) {
        super(props);
    }

    componentDidMount = () => {
        if (window && config.disablePromotions) {
            navigate(this.props.pageContext.dynamicDefaultPaths?.defaultPromotionsPath);
        }
    }

    render() {
        const { location, pageContext, component } = this.props;
        const meta = generateMeta(pageContext, location.href);
        const displayedComponent = component || <PromotionContainer component={<div dangerouslySetInnerHTML={{ __html: pageContext.html }} />} links={pageContext.links} />;
        const availableLeaderboardIds = availableLeaderboards.map(promotion => promotion.id);
        return (
            <Layout location={location} dynamicDefaultPaths={pageContext.dynamicDefaultPaths}>
                <SEO
                    title={pageContext.mainHeader}
                    description={pageContext.description}
                />
                <Helmet>
                    {meta}
                </Helmet>
                {!config.disablePromotions && <div className={s.container}>
                    <Title>$GOLD BANKROLL BUILDER</Title>
                    <div className={cx(s.tabBtnsContainer)}>
                        {pageContext.promotions.map((promotion, idx) => <RoundButton key={idx} active={promotion.id === pageContext.id} onClick={() => navigate(`/promotions/${promotion.id}`)}>{promotion.web.virtuePoker.leftMenuHeader}</RoundButton>)}
                        <RoundButton key="leaderboards-button" active={availableLeaderboardIds.includes(pageContext.id)} onClick={() => navigate(availableLeaderboards[0].path)}>Leaderboard Results</RoundButton>
                        <RoundButton key={drawingWinnersPromotionData.id} active={pageContext.id === drawingWinnersPromotionData.id} onClick={() => navigate(drawingWinnersPromotionData.path)}>{drawingWinnersPromotionData.leftMenuHeader}</RoundButton>
                    </div>
                    {displayedComponent}
                </div>}
            </Layout>
        );
    }
};

export default PromotionsTemplate;
