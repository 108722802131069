import React from 'react';
import PromotionsTemplate from '../../templates/promotions';
import DrawingWinners from '../../components/DrawingWinners';
import { drawingWinnersPromotionData } from '../../data/promotions';

const DrawingWinnersPage = ({ location, pageContext }) => (
    <PromotionsTemplate
        pageContext={{ ...pageContext, ...drawingWinnersPromotionData }}
        location={location}
        component={<DrawingWinners id={drawingWinnersPromotionData.id} />}
    /> 
);

export default DrawingWinnersPage;
