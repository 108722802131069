import React from 'react';
import GhostContentAPI from '@tryghost/content-api';
import DOMPurify from 'dompurify';
import config from '../../config';
import CircularLoader from '../CircularLoader';
import MSTable from '../HowToPlay/MSTable';

import * as s from './s.module.css';


interface Props {
    id?: any;
}

interface State {
    loading: boolean,
    error: string,
    navigationData: any,
    contentData: any,
    links: any,
    id?: any,
}

class PlayWithChipsAndEarnVxp extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            loading: true,
            error: '',
            navigationData: null,
            contentData: null,
            links: null,
            id: '',
        };
    }

    fromAllowDomain(url) {
        const allowedDomain = [
            'https://dev.virtue.poker',
            'https://qa.virtue.poker',
            'https://staging.virtue.poker',
            'https://virtue.poker',
        ];
        const isAllowedDomain = allowedDomain.reduce((acc, curr, ind) => url.startsWith(curr) || acc, false);
        const isRelativePath = url.startsWith('/');
        return isRelativePath || isAllowedDomain;
    }

    async componentDidMount() {
        const promotionsResponse = await this.getPromotions();
        if (!promotionsResponse.error) {
            let navigationData = [];
            let contentData = [];
            promotionsResponse.data.forEach((promo, id) => {
                navigationData.push({
                    title: promo.title,
                    id: `step${id}`,
                });
                let fn = DOMPurify.sanitize(promo.html, { ADD_TAGS: ["iframe"], ADD_ATTR: ['width', 'height', 'src', 'frameborder', 'allow', 'allowfullscreen'] });
                while (fn.includes('<figure class="kg-card kg-video-card">')) {
                    const p1 = fn.search('<figure class="kg-card kg-video-card">');
                    const t1 = fn.slice(p1);
                    const p2 = t1.search('</figure>');
                    const figure = t1.slice(0, p2 + String('</figure>').length);

                    const p3 = figure.search('<video');
                    const t3 = figure.slice(p3);
                    const p4 = t3.search('</video>');
                    const video = t3.slice(0, p4 + String('</video>').length);

                    const p5 = video.search('src="');
                    const t5 = video.slice(p5 + String('src="').length);
                    const p6 = t5.search('"');
                    const source = t5.slice(0, p6);

                    const firstPart = fn.slice(0, p1);
                    const lastPart = t1.slice(p2 + String('</figure>').length);
                    fn = firstPart.concat(`<figure class="kg-card kg-video-card modified"><video src="${source}" controls></video></figure>`).concat(lastPart);
                }
                contentData.push({
                    title: promo.title,
                    navigationreferenceid: `#step${id}`,
                    customComponent: () => <div dangerouslySetInnerHTML={{ __html: fn }}></div>,
                });
            });
            this.setState({
                navigationData: navigationData,
                contentData: contentData,
                loading: false,
                id: this.props.id,
            })
        } else {
            this.setState({ error: 'Error getting promotions config', loading: false });
        }
    }

    async getPromotions() {
        let response = { data: null, error: null };
        try {
            const api = new GhostContentAPI({
                url: config.ghost.url,
                key: config.ghost.contentKey,
                version: "v3",
            });
            const allPosts = await api.posts.browse({ include: 'tags' });
            const promotions = allPosts.filter(post => post.tags.filter(tag => tag.name === config.ghost.filterKey).length > 0).slice(0, config.ghost.limit);
            response.data = promotions;
        } catch (e) {
            response.error = e.message;
        }
        return response;
    }

    render() {
        if (this.state.loading) {
            return <div className={s.circularLoaderContainer}>
                <CircularLoader size={60} />
            </div>;
        }
        if (this.state.error) {
            return <div className={s.errorContainer}>
                <p>Currently unavailable. Please try again.</p>
            </div>
        }
        return (<MSTable navigationData={this.state.navigationData} contentData={this.state.contentData} links={this.state.links} longTitle={true} id={this.state.id} />)
    }
}

export default PlayWithChipsAndEarnVxp;
