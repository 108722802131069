import React from 'react';
import { navigate } from 'gatsby';

interface Props {
    component: any,
    links: any,
}
class PromotionContainer extends React.Component<Props> {
    constructor(props) {
        super(props);
    }

    allowDomainFilter(link) {
        const allowedDomain = [
            'https://dev.virtue.poker',
            'https://qa.virtue.poker',
            'https://staging.virtue.poker',
            'https://virtue.poker',
            'https://t.me/virtue_poker',
            'https://discord.gg/pu3e95wKMn',
            'https://twitter.com/virtue_poker'
        ];
        const isLinkToRemove = link && !link.target;
        const isAllowedDomain = allowedDomain.reduce((acc, curr, ind) => !isLinkToRemove && link.target.startsWith(curr) || acc, false);
        const isRelativePath = !isLinkToRemove && link.target.startsWith('/');
        return isLinkToRemove || isRelativePath || isAllowedDomain;
    }

    addLinks = () => {
        this.props.links && this.props.links.filter(this.allowDomainFilter).map(element => {
            let divToReplace = document.getElementById(element.id);
            if (divToReplace) {
                if (element.target) {
                    divToReplace.onclick = () => navigate(element.target);
                } else {
                    divToReplace.remove();
                }
            }
        });
    };

    componentDidMount = () => {
        this.addLinks();
    }

    componentDidUpdate = () => {
        this.addLinks();
    }

    render() {
        return this.props.component;
    }
}

export default PromotionContainer;